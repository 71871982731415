import { createStore } from 'vuex'

export default createStore({
  state: {
    name: '',
    events: [], 
    tickets: [],
    token: null,
    loggedInUser:null
  },
  getters: {
    getName(state) {
      return state.name;
    },

    getUser(state){
      return state.loggedInUser
    },

    getEvents(state) {
      return state.events;
    },
    getTickets(state) {
      return state.tickets;
    },
    
    isAuthenticated: state => !!state.token
   
  },
  mutations: {
    setName(state, newName) {
      state.name = newName;
    },
    setLoggedInUser(state,userId){
      state.loggedInUser = userId
    },
    setEvents(state, events) {

      state.events = events;
      const eventDates = events.map(event => ({ id: event.id, date: event.eventDate }));
      
      localStorage.setItem('eventDates', JSON.stringify(eventDates));
      localStorage.setItem('events', JSON.stringify(events));
    },
    setTickets(state, tickets) {
    
      const uniqueTickets = [];
      tickets.forEach(ticket => {
          const existingTicket = uniqueTickets.find(uniqueTicket => 
              uniqueTicket.ticket_name === ticket.ticket_name && 
              uniqueTicket.ticket_description === ticket.ticket_description
          );
          if (!existingTicket) {
              uniqueTickets.push({
                  ticket_name: ticket.ticket_name,
                  ticket_description: ticket.ticket_description,
                  admit_count: ticket.admit_count,
                  ticket_cost: ticket. ticket_cost,
              });
          }
      });
  
      // Update state and local storage with unique ticket data
      state.tickets = uniqueTickets;
      localStorage.setItem('tickets', JSON.stringify(uniqueTickets));

    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    clearUserData (state) {
      state.token = null
      state.loggedInUser = null
      localStorage.removeItem('token');
      localStorage.removeItem('tickets');
      localStorage.removeItem('events');
      localStorage.removeItem('eventDates');
      },
     

  },
  actions: {
    changeName({ commit }, newName) {
      commit('setName', newName);
    },
    setToken({ commit }, token) {
      commit('setToken', token)
    },

    setLoggedInUser({ commit }, userId) {
      commit('setLoggedInUser', userId)
    },
    setEvents({ commit }, events) {
      commit('setEvents', events);
    },
    setTickets({ commit }, tickets) {
      commit('setTickets', tickets);
    }
  },
  modules: {
  }
})
