<template>
  <!-- <div class="container d-flex justify-content-center thankYou">
    <div class="row">
        <div class="col-12 col-md-8">
            <img src="@/assets/images/thankyou.jpeg" class="img-fluid d-block mx-auto" alt="Thank You Image">
        </div>
    </div>
</div> -->

  <div class="agent-select d-flex justify-content-center align-items-center">
    <Loader class="loader" v-if="!membersLoaded" />
    <div class="name-selection" v-if="membersLoaded">
      <div class="modal-content">
        <h2 class="mb-3">Select Igiza Member</h2>
        <p class="text-muted">
          Choose Igiza (if you have no referral from a member)
        </p>
        <select class="form-select mb-3" v-model="selectedName">
          <option v-for="name in agentNames" :key="name">{{ name }}</option>
        </select>
        <button class="btn btn-custom" @click="selectName">Select</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";

export default {
  components: { Loader },
  data() {
    return {
      selectedName: null,
      agentNames: [], // Array to store agent usernames
      displayedEvents: [],
      membersLoaded: false,
    };
  },
  mounted() {
    this.fetchAgentNames(); // Call the method to fetch agent usernames when the component is mounted
    this.fetchEventData();
    this.fetchTicketData();
  },
  methods: {
    async fetchAgentNames() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}agents`
        ); // Fetch agent usernames from the API

        this.agentNames = response.data.agents.map((agent) => agent.username);
        this.membersLoaded = true;
      } catch (error) {}
    },
    selectName() {
      if (this.selectedName) {
        this.$store.commit("setName", this.selectedName);
        this.$router.push({ path: `/${this.selectedName}` });
      }
    },

    async fetchEventData() {
      this.$store.commit("clearUserData");
      try {
        // Make API call to fetch events data
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}events`
        );

        // Store the fetched events data in Vuex store
        this.$store.dispatch("setEvents", response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTicketData() {
      this.$store.commit("clearUserData");
      try {
        // Make API call to fetch tickets data
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}tickets`
        );

        // Store the fetched tickets data in Vuex store
        this.$store.dispatch("setTickets", response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.name-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay background */
  z-index: 1000; /* Ensure it's on top of everything */
  display: flex;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px; /* Optional: Adjust padding as needed */
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* Optional: Adds a background for better readability */
  z-index: 10; /* Ensures it stays above other elements */
}

.thankYou {
  margin-top: 10vh;
}

.border {
  border: green 2px solid;
}

.loader{
  margin-top: 25vh;
}
</style>
