<template>
  <!--Toast Alert Component is imported here-->
  <ToastNotifications ref="toastNotificationsRef" />

  <!-- The navigation container -->
  <nav class="navbar navbar-expand-md fixed-top">
    <div class="container">
      <a href="#" class="navbar-brand me-auto">
        <img
          src="../assets/images/igizalogo.png"
          alt="Harold Logo"
          height="40"
          class=""
          loading="lazy"
        />
      </a>

      <div>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item px-1">
            <a href="tel:+254743417198" class="btn rounded btn-custom">  
              <span class="navname mx-2">Call Support</span>
              <font-awesome-icon icon="fa-solid fa-phone" class="mx-1"</font-awesome-icon>
              
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
nav {
  background-color: #ffffff;
}
</style>
