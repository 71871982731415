<template>
  <div class="seat-chart mt-2">
    <div class="card">
      <div class="card-title">
        <h5 class="text-center mt-2">
          <strong> Select Available Seats</strong>
        </h5>
      </div>
      <div class="card-body">
        <div
          v-for="seat in pagedSeats"
          :key="seat.name"
          class="seat"
          :class="{ selected: seat.selected }"
          @click="toggleSeatSelection(seat)"
        >
          {{ seat.name }}
        </div>
      </div>
      <!-- Pagination controls -->
      <div class="pagination px-2">
        <button @click="prevPage" class="btn btn-custom mb-1">
          <font-awesome-icon
            icon="fa-solid fa-arrow-left"
            class="icon fa-lg"
          /><span class="mx-1">Previous</span>
        </button>
        <button @click="nextPage" class="btn btn-custom mb-1">
          More Seats
          <font-awesome-icon
            icon="fa-solid fa-arrow-right"
            class="icon fa-lg mx-1"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    seatsData: Array,
  },
  data() {
    return {
      seats: [],
      selectedSeats: [],
      currentPage: 1, // Track the current page
      seatsPerPage: 50, // Number of seats to display per page
    };
  },
  computed: {
    // Compute the paged seats based on the current page
    pagedSeats() {
      const start = (this.currentPage - 1) * this.seatsPerPage;
      const end = start + this.seatsPerPage;
      return this.seats.slice(start, end);
    },
  },
  watch: {
    seatsData: {
      handler(newVal) {
        // Parse the JSON-encoded seat_numbers field into an array of seat objects
        try {
          const seatNumbers = newVal.map((seat) => seat.seat_number);
          this.seats = seatNumbers.map((seatName) => ({
            name: seatName,
            selected: false, // Initialize the selected property
          }));
        } catch (error) {
          this.handleError(error);
        }
      },
      immediate: true, // Run the handler immediately on component mount
    },
  },
  methods: {
    toggleSeatSelection(seat) {
      seat.selected = !seat.selected;
      if (seat.selected) {
        this.selectedSeats.push(seat.name); // Add the selected seat to the array
      } else {
        this.selectedSeats = this.selectedSeats.filter(
          (selectedSeat) => selectedSeat !== seat.name
        ); // Remove the deselected seat from the array
      }
      this.$emit("update:selectedSeats", this.selectedSeats);
    },
    // Pagination methods
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      const totalPages = Math.ceil(this.seats.length / this.seatsPerPage);
      if (this.currentPage < totalPages) {
        this.currentPage++;
      }
    },

    async handleError(error) {
      if (error.response && error.response.data) {
        // Check if the error response has data and if it's in JSON format
        const responseData = error.response.data;

        if (typeof responseData === "object" && responseData.error) {
          // If it's a JSON error response, use the error message from the response.

          if (responseData.error.searchTerm) {
            const searchTermError = responseData.error.searchTerm;
            this.errorToast(searchTermError);
          } else {
            // Handle other error cases
            this.errorToast(responseData.error);
          }
        }
      } else {
        // If there's no error response data, show a generic error message.
        this.errorToast("An error occurred");
      }
    },
    async hanldleErrorTwo(error) {
      this.errorToast(error);
    },

    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },

    //success toast notification
    successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },
    warningToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("warning", message);
    },
  },
};
</script>

<style scoped>
/* ... existing styles ... */

.card {
  margin-bottom: 10px;
}

.card-body {
  display: flex;
  flex-wrap: wrap;
}

.seat {
  margin: 5px;
  width: 40px;
  height: 30px;
  background-color: #e0e0f0;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.selected {
  background-color: #2c2c2c;
  color: #C26920;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  margin-right: 5px;
  flex: 1;
}
</style>
