<template>
  <div class="mt-5">
    <footer class="text-center text-white">
      <div class="container">
        <div class="row p-3">
          <div class="col-md-6 text-md-start text-center">
            © {{ currentYear }} Built by:
            <a class="site-link link" href="https://haroldkerry.co.ke/"
              >haroldkerry.co.ke</a
            >
          </div>

          <div class="col-md-6 text-md-end text-center icon-container">
            <button
              class="btn btn-sm btn-custom text-white mx-1"
              data-bs-toggle="modal"
              data-bs-target="#privacyModal"
            >
              Privacy Policy
            </button>

            <a
              class="m-1 p-1"
              href="https://www.facebook.com/IgizaArtsProduction/"
              role="button"
            >
              <font-awesome-icon
                icon="fa-brands fa-facebook"
                class="icon fa-lg"
              />
            </a>
            <a class="m-1 p-1" href="#!" role="button">
              <font-awesome-icon
                icon="fa-brands fa-twitter"
                class="icon fa-lg"
              />
            </a>
            <a
              class="m-1 p-1"
              href="https://www.instagram.com/igiza_arts/"
              role="button"
            >
              <font-awesome-icon
                icon="fa-brands fa-instagram"
                class="icon fa-lg"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="privacyModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="privacyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="privacyModalLabel">Privacy Policy</h3>
        </div>
        <div class="modal-body">
          <h5>
            Use of Contact Details for Ticket Details and Event Information
          </h5>

          <p class="text-sm">
            By proceeding with the ticket booking process on our website, you
            consent to the collection and use of your contact details, including
            but not limited to your name, email address, and phone number, for
            the purpose of providing you with ticket details and information
            related to the event(s) you have booked. We value your trust, and we
            are committed to ensuring the privacy and security of your personal
            information.
          </p>

          <h5 class="text-sm">How We Use Your Contact Details</h5>

          <p class="text-sm">
            Ticket Details: We will use your contact details to send you
            important information related to your ticket purchase. This may
            include e-tickets, order confirmations, event updates, and any
            changes to event details, such as date, time, or venue.
          </p>

          <p class="text-sm">
            Event Information: We may also use your contact details to provide
            you with event-related information, such as event schedules,
            promotional offers, and relevant updates to enhance your event
            experience.
          </p>

          <h5>Data Security</h5>

          <p class="text-sm">
            We take the security of your personal information seriously. We have
            implemented technical and organizational measures to protect your
            data against unauthorized access, disclosure, alteration, and
            destruction.
          </p>

          <h5>Data Retention</h5>

          <p class="text-sm">
            We will retain your contact details for as long as necessary to
            fulfill the purposes outlined above or as required by applicable
            law. After the event is concluded, we will securely delete or
            anonymize your contact details.
          </p>

          <h5>Your Rights</h5>

          <p class="text-sm">
            You have the right to access, correct, or delete your personal
            information at any time. If you wish to exercise these rights or
            have any questions or concerns about our data privacy practices,
            please contact us.
          </p>

          <h5>Third-party Sharing</h5>

          <p class="text-sm">
            We do not share your contact details with event organizers, venues,
            or other parties involved in the event management process.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger w-100"
            data-bs-dismiss="modal"
          >
            Exit <font-awesome-icon icon="fa-solid fa-circle-xmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
footer {
  background-color: #2c2c2c;
  color: #fff;
}

a {
  text-decoration: none;
}

.bod {
  border: 2px solid green;
}

.icon {
  color: white;
}

.icon:hover {
  transform: scale(1.25); /* Zoom in slightly */
  transition: transform 0.3s ease; /* Smooth transition */
}

@media (max-width: 767px) {
  .icon-container {
    margin-top: 10px;
  }
}
</style>
